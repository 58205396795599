
export const defaultTitle = 'Merelogic Homepage';

export const intUrls = {
    home: '/',
    whoiam: '/#whoiam',
    clients: '/#clients',
    services: '/#services',
    portfolio: '/portfolio',
    pricing: '/pricing',
    contact: '/contact',
    hub: '/hub/'
}

export const extUrls = {
    viralEsoterica: 'https://viralesoterica.substack.com/',
    aiSafePipelines: 'https://ptp.cloud/building-ai-safe-biotech-data-pipelines/',
    refStack: 'https://stack.merelogic.net/',
    contentMarketing: 'https://airtable.com/appuSDJXGmy5wXYzx/pag5LS0qUA5XcuEj9/form',
    scalingBiotech: 'https://scalingbiotech.substack.com/',
    leadingTeams: 'https://saturncloud.io/resources/Leading_Biotech_Teams.pdf',
    unblocking: 'https://drive.google.com/file/d/15-bdoJbBvyGsJtvCIweNbO0NGGOOwbbO/view?usp=sharing',
    shortInsight1: 'https://www.linkedin.com/posts/jesse-johnson-biotech_everyones-busy-predicting-how-ai-tools-will-activity-7211013703041777664-mWt2',
    shortInsight2: 'https://www.linkedin.com/posts/jesse-johnson-biotech_chatgpt-is-already-killing-seo-but-it-wont-activity-7266839305396883456-hORE',
    deepDive1: 'https://scalingbiotech.substack.com/p/where-do-you-meet-your-users',
    deepDive2: 'https://viralesoterica.substack.com/p/when-96-of-the-market-isnt-ready',
}

export const urls = {
    ...intUrls,
    ...extUrls
};

const baseImages = {
    merelogicLogo: {image: 'merelogic_text.svg', alt: 'Merelogic logo' },
    scalingBiotech: { image: 'scaling_biotech.png', alt: 'Scaling Biotech newsletter' },
    leadingTeams: { image: 'leading_teams.png', alt: 'Leading Biotech Data Teams' },
    aiSafe: { image: 'ai_safe.png', alt: 'Building AI-safe Biotech Data Pipelines' },
    unblocking: { image: 'unblocking.png', alt: 'Unblocking Biotech Data Teams' },
    // clients: 'clients.png',
    jesse: { image: 'profile_jesse.png', alt: 'Jesse Johnson' },
}

export const images = Object.fromEntries(
    Object.entries(baseImages).map(([k, v]) => ([k, {
        image: `/static/assets/${v.image}`,
        alt: v.alt
    }]))
)

export const headerCells = [
    { display: 'Services', url: intUrls.services },
    { display: 'Portfolio', url: intUrls.portfolio },
    // { display: 'Newsletter', url: urls.viralEsoterica },
    { display: 'Resources', url: intUrls.hub },
]

export const footerLinks = [
    // { display: 'Contact', url: urls.contact },
    // { display: 'Services', url: intUrls.services },
    { display: 'Portfolio', url: intUrls.portfolio },
    { display: 'Resources', url: intUrls.hub },
]
