
import { ColumnLink } from './common.jsx';
import { PostRepurpose } from './hub_callouts.jsx';

export const articleTitles = {
    benefits: `Benefits of Relational Content`,
    dynamic: `Dynamic Resources`,
    decide: `Deciding What's Right For You`,
    transactional: `The Problem with Transactional Content`,
    relational: `What Makes Content Relational`,
    courses: `Online Courses`,
    communities: `Online Communities`,
    purpose: `The Purpose of Marketing Content`,
    hubs: `Knowledge Hubs`,
}

export const hubContents = {
    benefits: <><div class="hub-page-title-1">Benefits of Relational Content</div>
<div class="hub-page-text">The goal of Relational Content is to give readers the sense that they're interacting with a large body of knowledge that has been carefully curated so that they can return to it over and over. This article explores the following benefits of these content &quot;centers of gravity&quot;:</div>
<div class="hub-page-text">
<ol>
<li>You can improve search rankings without playing games that reduce content quality.</li>
<li>Decision makers will spend more time engaging with the content, then come back for more.</li>
<li>Readers will be more likely to share the content with their colleagues.</li>
<li>You'll be able to focus on content that better demonstrates your company's positioning and expertise while building a sense of reciprocity.</li>
</ol>
</div><div class="hub-page-text">These are all benefits of some unique properties of relational content.</div>
<div class="hub-page-title-2">Noticeable Effort</div>
<div class="hub-page-text">When readers/viewers encounter a content &quot;center of gravity,&quot; they will believe that someone put more time and effort into creating and coordinating the different pieces of content, so they'll subconsciously place more value on it.</div>
<div class="hub-page-text">In particular, readers/viewers will assume that the author(s) worked harder to make it accurate/useful and that they'll keep it around for a while.</div>
<div class="hub-page-text">This impression of both quality and permanence will make other sites more likely to link to the content (organic back links boosting SEO) and readers will be more likely to share it with colleagues.</div>
<div class="hub-page-title-2">Consistent Quality</div>
<div class="hub-page-text">One of the problems with getting information from web search is that readers don't know what they're getting when they click on a link. It could be the answer to their question or it could be AI-generated clickbait filled with pop-up ads and generic, useless information.</div>
<div class="hub-page-text">The benefit of relational content is that once a reader/viewer recognizes that one piece of content has genuinely useful information, they'll expect that the rest of it does as well.</div>
<div class="hub-page-text">As AI-generated garbage becomes more common and readers become increasingly suspicious of anything that sounds like it came from ChatGPT, they'll come to rely on sources that they can trust, returning to them often and sharing them with colleagues.</div>
<div class="hub-page-title-2">Depth of Content</div>
<div class="hub-page-text">Finally, relational content allows you to publish more general and more valuable/helpful information. Because it's not pandering to the SEO algorithm with every post, relational content can cover subjects that users may not search for, but will click if they see it. Shallower articles can be SEO-optimized and lead readers/viewers to deeper pieces of content.</div>
<div class="hub-page-text">This content covering deeper and more fundamental topics does more to demonstrate your company's expertise than anything that smells of clickbait. And by helping readers/viewers with deeper and more impactful information, this content builds goodwill that decision makers will remember when it comes time to buy.</div>
<div class="hub-page-title-2">Conclusion</div>
<div class="hub-page-text">Relational content takes more forethought and effort than transactional marketing content. But the increased value of the content outweighs the cost many times over.</div>
<div class="hub-page-text">To start building your own relational content strategy, read more about the four main kinds of relational content:</div>
<div class="hub-page-text"><ColumnLink url='/hub/hubs' text={`More on Knowledge Hubs`} /></div>
<div class="hub-page-text"><ColumnLink url='/hub/dynamic' text={`More on Dynamic Resources`} /></div>
<div class="hub-page-text"><ColumnLink url='/hub/courses' text={`More on Online Course Collections`} /></div>
<div class="hub-page-text"><ColumnLink url='/hub/communities' text={`More on Online Communities`} /></div>
<div class="hub-page-text">Or skip to the article that compares them all:</div>
<div class="hub-page-text"><ColumnLink url='/hub/decide' text={`Deciding What's Right For You`} /></div>
</>,
    dynamic: <><div class="hub-page-title-1">Dynamic Resources</div>
<div class="hub-page-text">Dynamic resources are informational tools that change, either based on external context or based on user interaction. They often take the form of dashboards or web apps. But a few factors make them distinct from other dashboards and apps:</div>
<div class="hub-page-text">
<ol>
<li>Dynamic resources are designed for prospective customers in addition to current users.</li>
<li>They're intended to be used more than once, so that users return to them regularly.</li>
</ol>
</div><div class="hub-page-text">Because they're intended for the top of the funnel rather than a lead magnet, most of these resources are usable without proving an email or creating an account. But this isn't a rule.</div>
<div class="hub-page-text">Of the four types of relational content covered in this guide, this is by far the least common (or at least the hardest to find.) But the fact that it's primarily marketing companies where I've found them says something about their potential.</div>
<div class="hub-page-text"><ColumnLink url='/hub/examples/dynamic' text={`Explore examples of dynamic resources`} /></div>
<div class="hub-page-title-2">Why Dynamic Resources</div>
<div class="hub-page-text">The benefit of a dynamic resource is that you don't have to keep generating content to get decision makers to come back. You may need to add or update the information, but because the resource provides dynamic data and/or multiple ways to explore it, users will always have a reason to return.</div>
<div class="hub-page-text">The downside is that building a dynamic resource takes more time and budget than a resource with static content. And in many contexts it can be difficult to find an angle that makes sense for your market.</div>
<div class="hub-page-title-2">What Makes a Dynamic Resource Good</div>
<div class="hub-page-text">There are a few properties that make a dynamic resource effective:</div>
<div class="hub-page-text">
<ol>
<li>Users should get immediate (or almost immediate) value from it.</li>
<li>It should be easy to interpret and understand the results of using it.</li>
<li>The value to the user should be clearly related to the product that it's marketing.</li>
</ol>
</div><div class="hub-page-title-2">Conclusion</div>
<div class="hub-page-text">Because of the difficulty of finding the right angle and the cost of implementing them, dynamic resources aren't as common as other types of relational content. But over time, as more companies recognize the value of relational marketing, the number is sure to increase.</div>
<div class="hub-page-text">Now that you have an understanding of dynamic resources, the next on the list is online courses.</div>
<div class="hub-page-text"><ColumnLink url='/hub/examples/dynamic' text={`Explore examples of dynamic resources`} /></div>
<div class="hub-page-text"><ColumnLink url='/hub/courses' text={`Learn about Online Course Collections`} /></div>
</>,
    decide: <><div class="hub-page-title-1">Deciding What's Right For You</div>
<div class="hub-page-text">While every company that has built relational content has picked a different mix of resources and strategies, there are some general patterns that suggest a common progression as companies and markets evolve. This article suggest some high-level rules of thumb for what will be appropriate and effective at each stage.</div>
<div class="hub-page-title-2">Stage 0: Strategic Exploration</div>
<div class="hub-page-text">Relational content is not an effective strategy before a company has found product-market fit and translated it into a solid content strategy. Each center-of-gravity resource requires a narrow scope, clear positioning and a narrow ICP (or occasionally a small number of narrow ICPs). Before making that investment, marketers should be fairly confident they've picked the right ones.</div>
<div class="hub-page-text">Marketing content can be an effective tool for exploring potential content strategies by evaluating what messages resonate both with potential customers and the internal team. A blog or newsletter that allows you to flexibility explore and iterate is the best platform for that.</div>
<div class="hub-page-text">Companies shouldn't begin investing in relational content until they've defined:</div>
<div class="hub-page-text">
<ul>
<li><div class="hub-page-text"><strong>ICP(s)</strong>: Both the kind of companies that tend to be your best customers and the roles within those companies that are most commonly the internal champions or other important stakeholders.</div>
</li>
<li><div class="hub-page-text"><strong>Motivation</strong>: What are the goals, pain points and constraints that drive customers to evaluate and adopt your software?</div>
</li>
<li><div class="hub-page-text"><strong>Epiphanies</strong>: What are the things you know that your prospective customers haven't learned yet, but knowing them would solve one or more of their problems?</div>
</li>
<li><div class="hub-page-text"><strong>Outcomes</strong>: What will companies that fit your ICP get from using your software?</div>
</li>
</ul>
</div><div class="hub-page-text">Once you've gotten enough traction, and done sufficient analysis of this traction, to define these things, you may be ready for relational content.</div>
<div class="hub-page-title-2">Stage 1: Adding Structure</div>
<div class="hub-page-text">Once you have a clearly defined content strategy and are ready to invest in relational content, the first investment should typically be a knowledge hub. The individual articles in this resource may not be significantly different from blog posts. But what makes articles into a knowledge hub is a common theme that drives the integrated structure between them.</div>
<div class="hub-page-text">In many cases, companies at this stage already have a substantial amount of content from blogs and newsletters that can be reworked to create the foundation for an integrated resource. However, it would be a mistake to pick a theme just because you already have content that fits it.</div>
<div class="hub-page-text">A knowledge hub is a substantial investment even if most of the content can be repurposed from other sources. To make sure you get the maximum return from that investment, select a theme that aligns closely with your content strategy by allowing you to:</div>
<div class="hub-page-text">
<ul>
<li><div class="hub-page-text">Address a problem that is a high priority to your ICP</div>
</li>
<li><div class="hub-page-text">Highlight the goals and pain points your software addresses and the impacts it creates</div>
</li>
<li><div class="hub-page-text">Cover the epiphanies that drive your approach</div>
</li>
</ul>
</div><div class="hub-page-text">Note that if you can't pick a single theme, it's OK to have more than one knowledge hub. Atlassian has three that serve distinct purposes, while HubSpot has different versions of a single core knowledge hub personalized for different ICPs.</div>
<div class="hub-page-text"><ColumnLink url='/hub/examples/hubs' text={`Explore examples of knowledge hubs`} /></div>
<PostRepurpose /><div class="hub-page-title-2">Stage 1a: Adding Dynamics</div>
<div class="hub-page-text">Dynamic resources are an option that often makes sense around the same time as a knowledge hub. But while existing content can often be repurposed into a knowledge hub, it's rare that it can be made into a dynamic resource.</div>
<div class="hub-page-text">Moreover, dynamic resources are often more complex and expensive to produce than knowledge hubs. But while they may require more technical resources, they make up for it by needing less content/copy. So the main obstacle to creating dynamic resources is identifying the right idea.</div>
<div class="hub-page-text"><ColumnLink url='/hub/examples/dynamic' text={`Explore examples of dynamic resources`} /></div>
<div class="hub-page-title-2">Stage 2: Enrollment</div>
<div class="hub-page-text">Whether or not it makes sense to start creating online courses depends primarily on the maturity of the subject. Is there material that would be better packaged and delivered as a course? Would it be materially more valuable/useful in that form rather than just a guide?</div>
<div class="hub-page-text">There are two main criteria that you should consider:</div>
<div class="hub-page-text">
<ul>
<li>Is the information definitive and authoritative?</li>
</ul>
</div><div class="hub-page-text">In many emerging markets, best practices are rules of thumb based on a handful examples. That's fine for a guide or a reference, which are expected to be reasonably opinionated. But online classes are associated with more stable, universal knowledge.</div>
<div class="hub-page-text">
<ul>
<li>Would the topic make sense as a certificate or credential?</li>
</ul>
</div><div class="hub-page-text">Finishing an online class should be associated with an accomplishment that encourages students to stick with it. Even if you don't offer a formal certificate or credential, being able to frame the material in that general way will give it that feeling.</div>
<div class="hub-page-text">If the subject matter fits these criteria, there's one more requirement: Your company needs to have a reputation as an authority on the subject. Because taking an online class is a commitment, students need a higher sense of trust in the information. If you're successful at Stage 1 of relational content, this shouldn't be a problem.</div>
<div class="hub-page-text"><ColumnLink url='/hub/examples/courses' text={`Explore examples of online course collections`} /></div>
<div class="hub-page-title-2">Stage 3: Community</div>
<div class="hub-page-text">Building an online community is the final, and least common form of relational marketing.</div>
<div class="hub-page-text">Most B2B SaaS companies that build online communities do it primarily for their customers. While this doesn't have all the benefits of relational marketing, there are a number of reasons to do it. Plus it's typically easier to do than building a broader online community and has a more immediate ROI.</div>
<div class="hub-page-text">Therefore, we recommend that companies who want to build an online community for relational marketing first build one for their existing customers. Then, if it's successful and meets a critical mass, they can open some or all of the functionality to the broader community.</div>
<div class="hub-page-text"><ColumnLink url='/hub/examples/communities' text={`Explore examples of online communities`} /></div>
<div class="hub-page-title-2">Conclusion</div>
<div class="hub-page-text">Relational marketing can increase engagement and sharing of all your content.</div>
<div class="hub-page-text">Merelogic can help you find the best strategy to maximize the return from the content you're already investing in.</div>
<div class="hub-page-text"><ColumnLink url='/contact' text={`Schedule a free consultation call`} /></div>
</>,
    transactional: <><div class="hub-page-title-1">The Problem with Transactional Content</div>
<div class="hub-page-text"><em>Transactional content</em> is marketing content designed to get a potential buyer's attention once, deliver a quick jolt of information and possibly a Call to Action (CTA),  then send them on their way. This includes blog posts, social media posts and even reports and white papers.</div>
<div class="hub-page-text">But a number of B2B SaaS companies are moving away from this, in favor of content that builds long-term relationships with potential buyers. And they have good reason to do this.</div>
<div class="hub-page-title-2">The 7-11-4 Rule</div>
<div class="hub-page-text">Transactional content has been the gold standard for top-of-funnel content marketing since the early days of digital marketing. It's primarily motivated by an idea called the &quot;7-11-4 rule&quot; that Google published in 2011. This rule claims that before a buyer will reach out to a company, they typically engage with its content for at least 7 hours, through 11 touch points and 4 separate locations.</div>
<div class="hub-page-text">The research behind the 7-11-4 rule appears to have been taken off Google's website, but others have published results with similar numbers. The goal of transactional content is to get those 11 touch points one at a time.</div>
<div class="hub-page-title-2">Catch and Release</div>
<div class="hub-page-text">To get those touch points, transactional content can be designed to help readers with a specific question or problem, shape their thinking around a complex topic, or just provide something interesting to read.</div>
<div class="hub-page-text">Readers may find the content through links in social media posts or social connections who forward the link. But most often, they find it by searching the web for a particular topic or question.</div>
<div class="hub-page-text">Users mostly interact with this content by quickly scanning it to see if it has what they're looking for. If it doesn't, they move on without reading it. If it does, they read it.</div>
<div class="hub-page-text">While reading it, they may notice who published it and pick up another touch point. If it's their 11th touch point, they may even click a CTA link.</div>
<div class="hub-page-text">But if they don't click the link, they move on to the next thing, and you're left making more content in the hope that you'll see them again. This catch-and-release strategy is what defines transactional content.</div>
<div class="hub-page-title-2">Missed Opportunities, Lost Value</div>
<div class="hub-page-text">The two biggest problems with transactional content are first, that it encourages you to focus on tactical topics that don't demonstrate your perspective and expertise, and second that after all the effort and investment to get a buyer's attention, you typically only get one touch point from it.</div>
<div class="hub-page-text">Because transactional content's success relies so heavily on getting users to click the link, it tends to focus on topics with high SEO rankings, headlines that get users to click, and quick, surface-level, bullet-heavy information.</div>
<div class="hub-page-text">At the same time, decision makers are increasingly using AI chat bots like ChatGPT to answer this technical/tactical questions, cutting down the number of touch points you can get from SEO.</div>
<div class="hub-page-title-2">Enter Relational Content</div>
<div class="hub-page-text">Relational content, on the other hand, can dig deeper into topics that demonstrate your positioning and expertise. And it does this while encouraging users to engage with multiple pieces of content, share it with colleagues and come back for more later.</div>
<div class="hub-page-text">To learn more about the kinds of value relational content is designed for, check out the next article:</div>
<div class="hub-page-text"><ColumnLink url='/hub/purpose' text={`The Purpose of Marketing Content`} /></div>
<div class="hub-page-text">Or to dive right into what relational marketing looks like, skip ahead to the one after that:</div>
<div class="hub-page-text"><ColumnLink url='/hub/relational' text={`What Makes Content Relational`} /></div>
</>,
    relational: <><div class="hub-page-title-1">What Makes Content Relational</div>
<div class="hub-page-text">Relational content is marketing content designed to encourage readers/viewers to interact with multiple pieces in a single visit, return later and share it with others. This multiplies engagement compared to fighting with algorithms for each individual interaction. This article explores what makes content relational, and what relational content looks like in practice.</div>
<div class="hub-page-title-2">Deliberate Structure</div>
<div class="hub-page-text">The topics addressed by relational content are often more general than transactional content: Instead of SEO-friendly topics like “10 ways to use X technology to achieve Y result”, relational articles tend to be more like “Common approaches to Y result.” But that’s more a result of being relational rather than what makes it relational: General topics fit more naturally into a larger body of work, but don’t work as well as stand-alone posts.</div>
<div class="hub-page-text">Instead, what makes content relational has more to do with how the content is framed and positioned rather than the information it conveys. So the four kinds of relational content are defined by this framing:</div>
<div class="hub-page-text">
<ul>
<li><strong>Knowledge Hubs</strong>: A knowledge hub is a collection of static content where each pieces is designed as part of a larger whole of inter-related articles/videos/etc. Knowledge hubs typically have a table of contents that suggests to readers that articles are meant to be read together rather than independently. Natural and deliberate links within the text encourage readers to explore other articles. And they often have additional suggested links at the end of articles or on a side bar, either to the next logical article or just to other related pieces.</li>
</ul>
</div><div class="hub-page-text"><ColumnLink url='/hub/hubs' text={`More on Knowledge Hubs`} /></div>
<div class="hub-page-text">
<ul>
<li><strong>Dynamic Resources</strong>: These present content that changes based on either external circumstances or user interactions. This includes things like self-assessments that give users custom-generated information, and dashboards that present continually updated information pulled from other sources. Users return to this content because they expect to learn something new each time - either because external circumstances have changed, or because they can interact with it differently, based on their latest needs.</li>
</ul>
</div><div class="hub-page-text"><ColumnLink url='/hub/dynamic' text={`More on Dynamic Resources`} /></div>
<div class="hub-page-text">
<ul>
<li><strong>Online Courses</strong>: This is exactly what it sounds like - usually a series of videos that users can step through in order. Many larger SaaS companies offer online courses about how to use their software. But for an online course to count as marketing content, it should be about a more general topic that users would want to learn about before making a decision to adopt the software. What makes an online course relational, in addition to the sequence between the lessons/videos is that users typically need to enroll in the course. Even if the course is free, this deliberate decision encourages users to engage with most or all of the content.</li>
</ul>
</div><div class="hub-page-text"><ColumnLink url='/hub/courses' text={`More on Online Course Collections`} /></div>
<div class="hub-page-text">
<ul>
<li><strong>Online Communities</strong>: This last one is also what it sounds like. As with online courses, online communities only count as marketing if they appeal to potential users/customers as well as actual users/customers. And like online courses, the main thing that makes them relational is that users deliberately enroll in them. Even though online communities don’t present content in the traditional sense, members of these communities use them to get information about the general topic. That information just comes mostly from community members rather than the marketing team.</li>
</ul>
</div><div class="hub-page-text"><ColumnLink url='/hub/communities' text={`More on Online Communities`} /></div>
<div class="hub-page-title-2">Not just for users</div>
<div class="hub-page-text">Many B2B Saas companies create resources like this for their existing users. What makes relational content different is that it's for both users and <strong>prospective users</strong>. So these knowledge hubs and online courses aren't just about how to use the product. They're about more general topics that are important to users whether or not they're already using the software/product.</div>
<div class="hub-page-title-2">Deeper content</div>
<div class="hub-page-text">The less immediate but perhaps more important aspect of relational content is the opportunity to create more fundamental and thoughtful content that's more like true thought leadership and less like click bait listicles.</div>
<div class="hub-page-text">
<ul>
<li><div class="hub-page-text">Because the individual pieces of content can build off each other, each piece can go deeper.</div>
</li>
<li><div class="hub-page-text">Because individual pieces aren't all beholden to SEO to get readers, they can cover more fundamental issues rather than focusing on tactical questions and popular SEO terms.</div>
</li>
<li><div class="hub-page-text">Because you're optimizing for depth of engagement over breadth of readers, content can be more specialized and opinionated.</div>
</li>
</ul>
</div><div class="hub-page-text">All of this allows you to optimize content for reciprocity, positioning and persuasion, trusting that users will stick around long enough to get to the calls to action.</div>
<div class="hub-page-text">But the next article provides even more details on the benefits of relational content.</div>
<div class="hub-page-text"><ColumnLink url='/hub/benefits' text={`Benefits of Relational Content`} /></div>
<PostRepurpose /></>,
    courses: <><div class="hub-page-title-1">Online Courses</div>
<div class="hub-page-text">Online Course Collections are just what they sound like. But not all online courses are relational content.</div>
<div class="hub-page-text">Many B2B SaaS companies create courses that teach users how to navigate their software. But these aren't marketing content: The students have already bought the software!</div>
<div class="hub-page-text">For online courses to be relational content, they need to cover more general topics that would be of interest to prospective users/buyers.</div>
<div class="hub-page-text"><ColumnLink url='/hub/examples/courses' text={`Explore examples of online course collections`} /></div>
<div class="hub-page-title-2">Why Online Courses</div>
<div class="hub-page-text">Online courses generally cover similar topics to blogs or knowledge hubs. They tend to leverage videos more than text, though knowledge hubs can also be built around videos.</div>
<div class="hub-page-text">What makes online courses unique as a form of relational content is enrollment: Users make an explicit decision to engage with the content. And that increases the chances that they'll actually do it.</div>
<div class="hub-page-text">The tradeoff for this deeper level of engagement is that not all content makes sense for online courses. In particular, online courses present content in a specified order so reference content doesn't makes sense.</div>
<div class="hub-page-text">Online courses tend to make the most sense for how-to content that teaches well defined skills or well estabished knowledge.</div>
<div class="hub-page-title-2">Paid or Free</div>
<div class="hub-page-text">Most online courses that are intended as relational content are offered free to encourage user engagement. But a few, such as the ones on Moz Academy, are paid.</div>
<div class="hub-page-text">One reason to make your online courses paid is to increase the perceived value of the course. This will increase the amount of time each user engages with the content, while likely decreasing the number of users who engage at all.</div>
<div class="hub-page-text">But it will also decrease or eliminate any sense of reciprocity. So from a marketing standpoint, the primary benefits of a paid course will be defining your positioning and/or persuading students to adopt a strategy that your product is designed to support.</div>
<div class="hub-page-text">Paid courses can also serve as a source of revenue. But if this is your primary motivation then they don't count as relational content. And if it isn't your primary motivation then you should make the decision based on how it will impact the effect it has on the reader/viewer.</div>
<div class="hub-page-title-2">Badges and Certifications</div>
<div class="hub-page-text">Some online course platforms allow users to earn badges and certifications for completing online courses. For these to be meaningful, your company should already be regarded as an authority in the space. Otherwise, it could seem like an overreach.</div>
<div class="hub-page-text">Badges and certifications are most effective if users have a way to brag about them. So, for example, both HubSpot and Salesforce allow users to share their badges through an associated online community.</div>
<div class="hub-page-title-2">Conclusion</div>
<div class="hub-page-text">Online courses are a great way to leverage enrollment to drive ongoing and repeated engagement. However, in addition to the additional time and effort they require, they are only effective for certain kinds of content.</div>
<div class="hub-page-text">And if you offer badges and/or certificates, they can be a good complement to the next kind of relational content, online communities.</div>
<div class="hub-page-text"><ColumnLink url='/hub/examples/courses' text={`Explore examples of online course collections`} /></div>
<div class="hub-page-text"><ColumnLink url='/hub/communities' text={`Learn about Online Communities`} /></div>
</>,
    communities: <><div class="hub-page-title-1">Online Communities</div>
<div class="hub-page-text">As with online courses, an online community only qualifies as relational marketing if it's open to members who aren't users of the product. In other words, this isn't a user group. It's an online community of people who fit your ideal customer profile (ICP).</div>
<div class="hub-page-text">Online communities aren't typically considered marketing content. But there are two reasons that online communities qualify as relational content (when they're open to the public):</div>
<div class="hub-page-text">
<ul>
<li><div class="hub-page-text">Members use communities as a way to get information and ideas, i.e. content</div>
</li>
<li><div class="hub-page-text">Online communities can achieve the goals of reciprocity, positioning and persuasion that motivate marketing content in general.</div>
</li>
</ul>
</div><div class="hub-page-text"><ColumnLink url='/hub/examples/communities' text={`Explore examples of online communities`} /></div>
<div class="hub-page-title-2">Why Online Communities</div>
<div class="hub-page-text">Like online courses, the primary benefit of online communities is that members deliberately enroll in them, increasing the chances that they will continue to engage with the content. But they also have some key benefits over other types of relational content:</div>
<div class="hub-page-text">
<ol>
<li><div class="hub-page-text">Unlike online courses, the engagement with online communities can continue indefinitely.</div>
</li>
<li><div class="hub-page-text">Most of the &quot;content&quot; in an online community is generated by members, allowing marketing teams to focus on other areas.</div>
</li>
<li><div class="hub-page-text">Communities generate continuous feedback on what prospective customers care about.</div>
</li>
</ol>
</div><div class="hub-page-text">On the other hand, there are also significant reasons why creating an online community may not make sense, or even be feasible:</div>
<div class="hub-page-text">
<ol>
<li><div class="hub-page-text">Online communities only work when there's a critical mass of engaged members. Early stage companies that don't have an established brand may struggle to build this critical mass.</div>
</li>
<li><div class="hub-page-text">If other closely related online communities already exist, creating another one could actually fragment the community, damaging any sense of goodwill.</div>
</li>
<li><div class="hub-page-text">Keeping an online community healthy and active requires a large investment and commitment.</div>
</li>
</ol>
</div><div class="hub-page-title-2">Conclusion</div>
<div class="hub-page-text">There are too many factors that go into creating a successful online community to even scratch the surface here. So this guide only provide some examples for inspiration and leave the details to other resources.</div>
<div class="hub-page-text"><ColumnLink url='/hub/examples/communities' text={`Explore examples of online communities`} /></div>
<div class="hub-page-text"><ColumnLink url='/hub/decide' text={`Deciding What's Right For You`} /></div>
</>,
    purpose: <><div class="hub-page-title-1">The Purpose of Marketing Content</div>
<div class="hub-page-text">To understand why relational marketing can be so much more effective than transactional, we first need to dig into the kind of value that you should expect from any kind of marketing content, particularly at the top of the funnel.</div>
<div class="hub-page-text">So this article explores four of the most common goals that marketing content may try to accomplish.</div>
<div class="hub-page-title-2">Call to Action (CTA)</div>
<div class="hub-page-text">The first potential goal is to get a reader/viewer to do something that moves them along your marketing and sales funnel: click to a product page, enter their email, book a demo. This is always the most tempting goal because the faster they do it, the better your metrics look.</div>
<div class="hub-page-text">The problem with this goal is that if a reader isn’t ready to follow the CTA then pushing them too hard can actually have a negative impact. It reminds them that you’re trying to sell them something, and detracts from the other goals below.</div>
<div class="hub-page-title-2">Reciprocity</div>
<div class="hub-page-text">This is the most charitable interpretation of “getting your name out there.” Your ICP is looking for information to help them with a problem. You give them that help, then subtly remind them it was you by placing your logo somewhere on the page. It’s almost like a paid ad, but instead of paying with money, you pay with the effort of creating the content.</div>
<div class="hub-page-text">Because of the subtle psychology of reciprocity, the reader develops positive associations with your brand. Though if you overdo it with CTAs, you’ll just remind them that your real goal is to sell them something, and that kind of kills the subconscious impact.</div>
<div class="hub-page-text">This content should specifically target the problems that your ICP faces. Strictly from the perspective of reciprocity, it doesn’t matter so much if the subject is related to what you actually do. But since you want to associate the feelings with the problems your product solves, it probably matters a little.</div>
<div class="hub-page-title-2">Positioning</div>
<div class="hub-page-text">Going beyond a general sense of reciprocity, this goal is for readers to associate your company/product with a particular approach/opinion/mission/etc. The content doesn’t have to explicitly stay “this is what we stand for.” In fact, it probably shouldn’t. Instead, it should imply this based on the topic and how it’s presented. This can be through more strategic “thought leadership” or just by biasing towards tactical content that aligns with your position.</div>
<div class="hub-page-text">Compared to Reciprocity, this content should be more closely aligned with what you actually do. On the other hand, it’s probably less appealing to a general ICP because it’s targeting ICPs who align with, or are open to, this targeted positioning.</div>
<div class="hub-page-title-2">Persuasion</div>
<div class="hub-page-text">The goal here is to actually change your reader’s understanding of their needs, their approach, etc. in a way that makes your product the ideal solution. This isn’t deception (or at least it shouldn’t be): This content should help readers understand their problems more clearly and in more detail. And if your product is actually the right one for their problem, then understanding the problem better should help them see that.</div>
<div class="hub-page-text">This feels similar to positioning, in that both involve writing about things that make your product unique and better. But while positioning is all about associating your product with that position, persuasion is about helping the reader adopt that position. If you is persuasion, it doesn’t matter if your logo is attached to the content. For example, if ChatGPT decides to appropriate the content as its own, you still achieve your goal.</div>
<div class="hub-page-title-2">Conclusion</div>
<div class="hub-page-text">These are four of the most common goals that marketing teams think of when developing content. And transactional content tends to get in the way of each one. So the next article introduces a new approach that's much more effective at all of them.</div>
<div class="hub-page-text"><ColumnLink url='/hub/relational' text={`What Makes Content Relational`} /></div>
</>,
    hubs: <><div class="hub-page-title-1">Knowledge Hubs</div>
<div class="hub-page-text">A <em>knowledge hub</em> is a collection of mostly static content - text and/or videos - that leverages inter-relational structure between components. Each component may be similar in composition to a blog post, but a few things make the overall resource a knowledge hub:</div>
<div class="hub-page-text">
<ol>
<li>A consistent theme or story that frames the individual components as part of a larger whole.</li>
<li>A table of contents that defines the overall structure and encourages navigation.</li>
<li>Deliberate links between components, particularly at the end of each piece, identifying natural next steps.</li>
</ol>
</div><div class="hub-page-text"><ColumnLink url='/hub/examples/hubs' text={`Explore examples of knowledge hubs`} /></div>
<div class="hub-page-title-2">Why Knowledge Hubs</div>
<div class="hub-page-text">Knowledge hubs are typically the best place to start with relational content because the main additional effort compared to a standard blog or newsletter is planning and coordination. In fact, you may be able to repurpose existing marketing content to create a knowledge hub.</div>
<div class="hub-page-text">For many companies, the main obstacle to creating a knowledge hub is clarifying your content strategy enough to identify the right topics and framing. With a blog, you can explore different topics, defining the focus of each post as you go.  But a knowledge hub requires investing in a single relatively narrow subject, targeting a specific ICP.</div>
<div class="hub-page-text">If you already have a clear ICP and positioning, finding the right topic for a knowledge hub may be relatively easy. If not, you may need to do some additional market research before you get started.</div>
<div class="hub-page-text">Once you've identified a target ICP and a topic, there are a few more factors to consider.</div>
<div class="hub-page-title-2">Do White Papers Count?</div>
<div class="hub-page-text">Many companies publish longer content in the forms such as downloadable white papers and case studies. These typically don't have the same impact as relation content for a few reasons:</div>
<div class="hub-page-text">
<ol>
<li><div class="hub-page-text">Because there's no way to link into specific chapters or sections in downloadable content, there are fewer opportunities to lead readers into the resource.</div>
</li>
<li><div class="hub-page-text">The framing implies that they are independent, stand-alone resources that should be read through once, rather then exploring and returning.</div>
</li>
<li><div class="hub-page-text">The extra step of downloading the content creates a barrier to entry. If the reader has to enter their email, that's an even larger barrier.</div>
</li>
</ol>
</div><div class="hub-page-text">So the term <em>knowledge hub</em> will only refer to resources published as web pages that encourage both linear and non-linear navigation.</div>
<div class="hub-page-title-2">Guide vs Reference</div>
<div class="hub-page-text">Most knowledge hubs fall into one of two categories:</div>
<div class="hub-page-text">
<ul>
<li><div class="hub-page-text"><strong>Guides</strong> are framed as how-to guides that help readers learn a particular process or skill. They're often structured as a series of lessons that are designed to be followed in order.</div>
</li>
<li><div class="hub-page-text"><strong>References</strong> provide information in a way that makes answers to specific questions easy to find. They're less likely to have a specific sequence/path for readers to follow. The information also tends to focus more on definitions and context rather than guidance.</div>
</li>
</ul>
</div><div class="hub-page-text">Both types of knowledge hubs can be very effective, depending on the circumstances.</div>
<div class="hub-page-text">Reference sites are typically better for introductory information. These are good for targeting ICPs looking for help outside their comfort zone. For example, because Cloudflare's ICP is a business leader who is worried about cybersecurity but isn't a technical expert, their reference-style &quot;Learning Center&quot; explains introductory security terms in deep detail.</div>
<div class="hub-page-text">Guides are typically better for ICPs at any level of technical or operational expertise who are looking for more strategic guidance. For example, Hubspot's &quot;Guide to Connecting in the Age of AI&quot; specifically targets leaders looking for effective strategies in a shifting landscape. Meanwhile, Atlassian's &quot;The Workstream&quot; covers a range of topics from introductory project management to advanced topics.</div>
<div class="hub-page-text">In fact, Atlassian (one of the earliest innovators in relational content) has both a reference knowledge hub (their &quot;Agile Coach&quot;) and a number of guides.</div>
<div class="hub-page-title-2">Single-page vs Multi-page</div>
<div class="hub-page-text">This is a more technical factor, but it does play a part in perception:</div>
<div class="hub-page-text">Some knowledge hubs are implemented as a single page with the table of contents and other links pointing to sections within. This can help make the content feel like a single cohesive resource. But it also makes the entire resource feel like an extended blog posts.</div>
<div class="hub-page-text">Anything that makes a reader feel like they need to finish in a single sitting increases the chances that they'll never start. So an important aspect of a knowledge hub is that readers have a way to keep track of where they left off. Separate pages allow them to bookmark places to come back to later.</div>
<div class="hub-page-text">For example, Wistia's single-page Guide to Video Marketing regularly encourages readers to get the downloadable version because it's the only way to keep track of where they are. A multi-page site would help eliminate this problem.</div>
<div class="hub-page-text">Single-page may be reasonable for shorter knowledge hubs, but in most cases multi-page will be a better decision.</div>
<div class="hub-page-title-2">Closed vs Evolving</div>
<div class="hub-page-text">Most knowledge hubs are published as complete resources. But depending on the framing, readers may have different expectations about how the content will continue to evolve.</div>
<div class="hub-page-text">
<ul>
<li><div class="hub-page-text"><strong>Closed</strong> knowledge hubs are presented as one-and-done: Once they're published, nothing will be added or changed.</div>
</li>
<li><div class="hub-page-text"><strong>Evolving</strong> knowledge hubs are framed so that readers expect you to continue mostly adding and occasionally updating pages.</div>
</li>
</ul>
</div><div class="hub-page-text">There are obvious reasons to create closed resources: You want to present complete, professional content. And if readers think that the resource is going to change and improve, they may wait to engage with it.</div>
<div class="hub-page-text">But in practice, closed resources just give readers fewer reasons to come back. A closed knowledge hub, particularly a shorter one, is more likely to feel like an extended blog post or white paper. This increases the chance that they'll put off reading it until they have time, instead of consuming it in multiple sessions.</div>
<div class="hub-page-text">In fact, by explicitly stating that a knowledge hub is evolving, you can encourage readers to subscribe for updates. Atlassian's Team Playbook is a good example of this strategy.</div>
<div class="hub-page-text">Note that it's easier to frame a reference site as evolving than a guide. But in either case it's typically a more effective strategy.</div>
<PostRepurpose /><div class="hub-page-title-2">Conclusion</div>
<div class="hub-page-text">Knowledge hubs are a great way to get started with relational content. But once you have that set, the next step is dynamic resources.</div>
<div class="hub-page-text"><ColumnLink url='/hub/examples/hubs' text={`Explore examples of knowledge hubs`} /></div>
<div class="hub-page-text"><ColumnLink url='/hub/dynamic' text={`Learn about Dynamic Resources`} /></div>
</>,
}