import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PostHogProvider} from 'posthog-js/react'

import PricingPage from './pricing.jsx';
import PortfolioPage from './portfolio.jsx';
import Hub from './hub.js';
// import MigrationPlan from './migration_plan.jsx';

import PageWrapper, { HeroBlock, Divider, ColumnLink, Consultation, List, ColumnParagraph, ColumnTitle, ColumnTitlePadding, ColumnSubtitle, ColumnSection, CenteredText } from './common.jsx';

import { images, urls } from './content.jsx';

import './index.css';

const REACT_APP_PUBLIC_POSTHOG_KEY = 'phc_rqQTqhCerA7U5dBgmvJ8vinc4u0sybDGPjQxKfFG3wK'
const REACT_APP_PUBLIC_POSTHOG_HOST = 'https://us.i.posthog.com'

const HomePagePage = (props) => {
    return PageWrapper(<>
        <HeroBlock
            title={`Stop Publishing Transactional Content`}
            subtitle='Merelogic creates relational content that drives engagement and sharing'
        />

        <Divider id='problem'/>
        { ColumnSection([
            ColumnTitle('Transactional Content is a Missed Opportunity'),
            ColumnParagraph(`
It's gets a buyer's attention once, delivers a quick jolt of information and possibly
a call to action, then sends them on their way.`),
ColumnParagraph(`
But most decision makers need to interact with 8 to 13 pieces of content before
they make a buying decision. So each one-off interaction is a missed opportunity to
run up the score.`),
        ], [
            ColumnTitle('Relational Content Boosts Engagement'),
            ColumnParagraph(`
It's deliberately designed to encourage navigation between individual posts, videos and
articles by linking them into larger, in-depth resources that readers will explore
and engage with.`),
            ColumnParagraph(`
And because relational content feels more substantial and permanent, readers are more likely
to bookmark it, return to it and share it with colleagues.`),
            ColumnParagraph(
                <ColumnLink text='Learn more about relational content' url={ urls.hub } />
            )
        ]) }

        <Divider id='whoiam'/>
        { ColumnSection([
            ColumnTitle('Who I am'),
            ColumnParagraph(`
I've been building loyal audiences with my writing about data, machine learning
and AI since 2013, while working in engineering and leadership roles at companies from
Google to biotech startups.`),
            ColumnParagraph(`
As a decision maker in multiple technical organization, I learned first-hand how
teams identify and evaluate software tools. And by writing for readers like myself,
I learned the hard way what works and doesn't.`),
            ColumnParagraph(`I'm on a mission to make B2B SaaS marketing relational.`),
ColumnParagraph(`Are you ready to join me?`),
        ], [
            ColumnTitlePadding(),
            ColumnParagraph(
                <div className='portrait-wrapper'>
                    <img width='300' src={ images.jesse.image } alt={ images.jesse.alt } />
                    <div>Jesse Johnson</div>
                    <div>Content Strategist & Writer</div>
                </div>
            ),
            ColumnParagraph(
                <ColumnLink text='See my work' url={ urls.portfolio } />
            )
        ]) }
        <Divider id='clients' />
        { ColumnSection([
            ColumnTitle('My Clients'),
            ColumnParagraph(`I work with B2B SaaS companies who:`),
            List([
                'Develop cutting edge software for emerging technical niches',
                'Have found product-market fit and are ready to scale',
                'Recognize trust and authority as central to their marketing strategy',
            ], 'wide')
        ], [
            ColumnTitlePadding(),
            ColumnParagraph(`
Relational content marketing is not for everyone. It may not make sense for large account-based
sales, or established markets where customers only care about features.`),
            ColumnParagraph(`
But if your go-to-market strategy is built around serious investment in content marketing, I
can help you boost engagement and ROI.`)
        ])
        }
        <Divider id='services' />
        { ColumnSection([
            ColumnTitle('Services'),
            ColumnSubtitle('Strategy'),
            List([
                'Identify the types of resources that best fit your go-to-market strategy',
                'Define positioning and narratives that resonate with you ICP',
                'Create plans to maximally leverage your existing content and reputation',
            ], 'wide'),
        ], [
            ColumnTitlePadding(),
            ColumnSubtitle('Execution'),
            List([
                'Market research to identify themes and topics most relevant to your ICP',
                'Content plans to repurpose existing posts into in-depth, unified resources',
                'Writing new content and refactoring existing content',
            ], 'wide'),
        ]) }
        {/* { CenteredText(`I tailor every project to the specific needs of my clients. Let's explore what relational content could like like for you.`) } */}
        { CenteredText(<Consultation />) }
    </>)
}

const root = ReactDOM.createRoot(document.getElementById('root'));

const NotFound = (props) => {
    return PageWrapper(<>
        <HeroBlock
            title='Page not Found'
        />
        { CenteredText(
            <ColumnLink text='Return to the Merelogic homepage' url={ urls.home } />
        )}
    </>, 'Page Not Found')
}

const Noai = (props) => {
    return PageWrapper(<>
        { ColumnSection([
            ColumnTitle(`Narrative Optimization for AI Report`),
            ColumnParagraph(`
I'm no longer offering free or paid reports on how AI chatbots answer questions about B2B software.`),
ColumnParagraph(`
The more research I've done about how AI chatbots answer these kinds of questions, the more I realize I don't know enough to confidently offer the products/services. I'm continuing to monitor the space, and maybe one day I'll try again. But today isn't that day.`),
        ], [
            ColumnTitlePadding(),
            ColumnTitlePadding(),
            ColumnParagraph(`
Instead, I'm back to focusing on an area that I know how to help B2B SaaS companies: Creating marketing content that builds trust and credibility with your target customers.`),
            ColumnParagraph(<ColumnLink text='Learn more here' url={ urls.home } />)
        ])}
    </>)
}

const posthogOptions = {
    api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
}

root.render(
    <React.StrictMode>
        <PostHogProvider
            apiKey={REACT_APP_PUBLIC_POSTHOG_KEY}
            options={posthogOptions}
        >
            <Router>
                <Routes>
                    <Route path={'/'} element={<HomePagePage />} />
                    <Route path={'portfolio'} element={<PortfolioPage />} />
                    {/* <Route path={'migration_plan'} element={<MigrationPlan />} /> */}
                    <Route path={'hub/*'} element={<Hub />} />

                    <Route path={'pricing'} element={<PricingPage />} />
                    <Route path={'report_card'} element={<Noai />} />
                    <Route path={'noai'} element={<Noai />} />

                    <Route path={'*'} element={<NotFound />} />
                </Routes>
            </Router>
        </PostHogProvider>
    </React.StrictMode>
);
