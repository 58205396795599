import React, { useState } from 'react';
import { Route, Routes, useLocation, useParams, useNavigate } from 'react-router-dom';
import { GoTriangleRight, GoTriangleDown } from 'react-icons/go';
import Select from 'react-select'

import PageWrapper, { ColumnLink } from './common.jsx';

import { exampleData } from './example_data.js';
import { hubContents, articleTitles } from './hub_contents.js';

import './hub.css';


// Note to self - Inserting images: ![Spaceship](relational.png)

const siteTitle = `A Marketer's Guide to Relational Content`;

const toc = {
    Articles: [
        'transactional',
        'purpose',
        'relational',
        'benefits',
    ],
    'Kinds of Relational Content': [
        'hubs',
        'dynamic',
        'courses',
        'communities',
        'decide',
    ],
    Examples: [
        'examples/hubs',
        'examples/dynamic',
        'examples/courses',
        'examples/communities',
    ]
}

const getTitle = (key) => {
    if (key in articleTitles) {
        return articleTitles[key];
    } else {
        return articleTitles[key.split('/')[1]];
    }
}

const shouldBeOpen = (path, chapters) => {
    for(const chapter of chapters) {
        if (path === chapter) { return true; }
    }
    return false;
}

export const ToCSection = (props) => {
    const [open, setOpen] = useState(shouldBeOpen(props.path, props.chapters));

    return <div>
        <div className='hub-toc-section' onClick={ () => setOpen(!open)}>
            {
                open ?
                <GoTriangleDown size={24} style={{ verticalAlign: "middle" }}/> :
                <GoTriangleRight size={24} style={{ verticalAlign: "middle" }}/>
            }
            {props.name}
        </div>
        {open ? props.chapters.map((chapter) => <>
            <a href={`/hub/${chapter}`} key={ chapter }><div
                className={'hub-toc-chapter ' + (props.path === chapter ? 'hub-toc-active' : 'hub-toc-none')}
            >{getTitle(chapter)}</div></a>
        </>) : null}
    </div>
}

export const HubToC = (props) => {
    const location = useLocation();
    const path = location.pathname.split("/hub/")[1].replaceAll('%20', ' ');

    return <>
        <a href={`/hub`}><div className='hub-toc-top hub-toc-none'>Home</div></a>
        {Object.entries(toc).map(([name, chapters]) =>
            <ToCSection name={ name } key={ name } chapters={ chapters } path={ path }/>)}
    </>
}

export const HubToCDropdown = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split("/hub/")[1].replaceAll('%20', ' ');

    const options = Object.entries(toc).map(([name, chapters]) => ({
        label: name,
        options: chapters.map((chapter) => ({label: getTitle(chapter), value: chapter}))
    }))

    return <Select
        options={options}
        value={ {label: getTitle(path), value: path} }
        onChange={(event) => navigate(`/hub/${event.value}`)}
    />
}
const SubscribeBox = (props) => {
    return <div className='subscribe-outer'>
        <div className='hub-page-text'>
            This site is a work in progress, with articles added and updated regularly.
        </div>
        <div className='hub-page-text'>
            To get notifications of these changes as well as complementary information
            and inspiration, subscribe to the weekly Relational Content newsletter.
        </div>
        <div className='hub-page-text'>
            <ColumnLink
                url={'https://relationalcontent.substack.com/archive'}
                text={'Preview before you subscribe'}
                newTab={true}
            />
        </div>
        <div className='subscribe-container'>
            <iframe
                src="https://relationalcontent.substack.com/embed"
                title="Subscribe"
                // width="480"
                height="150"
                frameborder="0"
            />
        </div>
    </div>
}

export const hubPage = (contents, title) => {
    return PageWrapper(<>
        <div className='hub-toc-dropdown'><HubToCDropdown /></div>
        <div className='hub-outer'>
            <div className='hub-toc'>
                <HubToC />
            </div>
            <div className='hub-contents'>
                { contents }
            </div>
        </div>
        <SubscribeBox />
    </>, title)
}

const ExamplesPage = (props) => {
    const params = useParams();

    return hubPage(<>
        <div className='category-outer'>
            <div className='hub-page-title-1'>{ articleTitles[params.category] }</div>
            <div class="hub-page-text">
                <ColumnLink url={`/hub/${params.category}`} text={`Learn more about ${articleTitles[params.category]}`} />
            </div>
            {exampleData
                .filter((e) => (e.Package === articleTitles[params.category]))
                .sort((e, f) => (e.Name.toUpperCase() < f.Name.toUpperCase() ? -1 : 1))
                .map((e) => <div className='site-outer' key={ e.name }>
                        <div className='site-name'>{ e.Name }</div>
                    <div className='hub-page-text'>
                        { e.Description }
                    </div>
                    <div className='hub-page-text'>
                        <ColumnLink
                            url={ e.Link }
                            newTab={ true }
                            text='Visit'
                        />
                    </div>
                </div>)
            }
            <div class="hub-page-text">
                <ColumnLink url={`/hub/${params.category}`} text={`Learn more about ${articleTitles[params.category]}`} />
            </div>

        </div>
    </>, `Examples: ${articleTitles[params.category]} - ${siteTitle}`)
}

const ArticlePage = (props) => {
    const location = useLocation();
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const pageKey = pathSegments[pathSegments.length - 1] || "default";

    return hubPage(<>
        { hubContents[pageKey] }
    </>, `${articleTitles[pageKey]} - ${siteTitle}`)
}

const HubMain = (props) => {
    return PageWrapper(<>
        <div className='hub-page-title-1'>{siteTitle}</div>
        <div className='hub-main-outer'>
            {Object.entries(toc).map(([name, chapters]) => <div className='hub-main-section' key={ name }>
                <div className='hub-main-section-title'>{name}</div>
                {chapters.map((chapter) => <>
                    <a href={`/hub/${chapter}`} key={ chapter }><div className='hub-main-chapter'>{getTitle(chapter)}</div></a>
                </>)}
            </div>)}
        </div>
        <SubscribeBox />
    </>, siteTitle)
}

const Hub = (props) => {
    return <Routes>
        <Route path={'/'} element={<HubMain />} />
        <Route path={'examples/:category'} element={<ExamplesPage />} />
        {Object.entries(toc)
            .filter(([name, chapters]) => (name !== 'Examples'))
            .map(([name, chapters]) => <>
            {chapters.map((k) => <Route path={k} key={k} element={<ArticlePage />} />)}
        </>)}
    </Routes>
}

export default Hub;