export const exampleData = [
  {
    "Name": "Hubspot Community",
    "Description": "An online community primarily for Hubspot users, with limited access for non-users. Includes network/discussion groups, information about events, support resources, product feedback and a place to display certifications/awards from Trailhead.",
    "Company": [
      "recxp1gQFNK7yC3Vr"
    ],
    "Link": "https://community.hubspot.com/",
    "Package": "Online Communities",
    "Publish": true
  },
  {
    "Name": "Hubspot Website Grader",
    "Description": "Enter a URL and an email address. Get a simple but thorough report with insights into how to better optimize the site.",
    "Company": [
      "recxp1gQFNK7yC3Vr"
    ],
    "Link": "https://website.grader.com/",
    "Package": "Dynamic Resources",
    "Publish": true
  },
  {
    "Name": "Intel471 Cybersecurity Glossary",
    "Description": "A searchable, alphabetical list of cybersecurity terms and definitions.",
    "Company": [
      "recoxBS7kC2s9KdId"
    ],
    "Link": "https://intel471.com/glossary",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Medidata Decentralized Clinical Trials Assessment",
    "Description": "An 8-question evaluation to determine how comprehensive your decentralized clinical strategy is.",
    "Company": [
      "rec3hW1Z5Mhg6yVrO"
    ],
    "Link": "https://www.medidata.com/en/clinical-trial-solutions/virtual-clinical-trials/decentralized-clinical-trials-assessment/",
    "Package": "Dynamic Resources",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Cloudflare Radar",
    "Description": "Interactive dashboard of internet security/traffic trends. ",
    "Company": [
      "recvDcullMECLJUqX"
    ],
    "Link": "https://radar.cloudflare.com/",
    "Package": "Dynamic Resources",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Wistia Guide to Video Marketing",
    "Description": "A linear how-to guide that covers the fundamentals of making videos, all in one page. Not much inter-linking, and no easy way to come back part way through.",
    "Company": [
      "rec2pHNK1nm7cZVMb"
    ],
    "Link": "https://wistia.com/about/video-marketing-guide",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "AppViewX Education Center",
    "Description": "An encyclopedia for all things PKI and network security. Foundational articles organized by higher-level topics. Mostly What is... articles.",
    "Company": [
      "recGv660ZyO1Sc1Lq"
    ],
    "Link": "https://www.appviewx.com/resources/education-center/",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Cloudflare Learning Center",
    "Description": "Each section covers a core concept, splitting it into chapters that each answer a single question. Articles refer to each other and a side bar links to related articles.",
    "Company": [
      "recvDcullMECLJUqX"
    ],
    "Link": "https://www.cloudflare.com/learning/",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "KnowBe4 Ultimate Guide to Security Awareness Training",
    "Description": "An extensive one-page guide to Security Awareness Training, which is one of the core products that KnowBe4 offers. KnowBe4 has a number of similar guides on other topics that aren't listed separately in this list.",
    "Company": [
      "recCtJutQlQh3s1pl"
    ],
    "Link": "https://www.knowbe4.com/security-awareness-training",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "JMP Statistical Thinking for Industrial Problem Solving (STIPS)",
    "Description": "This is an online course on which statistical approaches to use \u2013 and how to interpret and communicate results - in different fields.",
    "Company": [
      "recRbwWbY4mLT6FbU"
    ],
    "Link": "https://www.jmp.com/en/online-statistics-course",
    "Package": "Online Courses",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Atlassian - Agile Coach",
    "Description": "A collection of articles focused on defining core topics, each ending with links to related content. Articles are focused on defining core concepts.",
    "Company": [
      "recRS5UKag0lGtc65"
    ],
    "Link": "https://www.atlassian.com/agile",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Thryv business tools",
    "Description": "A collection of tools for things like building invoice templates and calculating whether you're charging enough.",
    "Company": [
      "recgaRnXHrCGKExPw"
    ],
    "Link": "https://www.thryv.com/free-tools/",
    "Package": "Dynamic Resources",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Gitlab's TeamOps Online Course",
    "Description": "Online course introducing a methodology that GitLab created called TeamOps",
    "Company": [
      "recOZ9VbgeZctkLyL"
    ],
    "Link": "https://about.gitlab.com/teamops/",
    "Package": "Online Courses",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Hubspot Academy",
    "Description": "A large number of online courses, organized by subject. Includes certifications. Split half and half between general marketing topics and how to use Hubspot.",
    "Company": [
      "recxp1gQFNK7yC3Vr"
    ],
    "Link": "https://academy.hubspot.com/",
    "Package": "Online Courses",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Moz Academy",
    "Description": "Online courses on a number of marketing contents. All are paid except for the free \"How to use Moz\" class. ",
    "Company": [
      "rectExDr7MJLb7ANV"
    ],
    "Link": "https://academy.moz.com/",
    "Package": "Online Courses",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Mend.io Vulnerability Database",
    "Description": "An open source database of known vulnerabilities, searchable by ID or by project name.",
    "Link": "https://www.mend.io/vulnerability-database/",
    "Package": "Dynamic Resources",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "New Relic University",
    "Description": "Training and certification programs aimed at junior developers and students.",
    "Company": [
      "recU3ODSwlEqn4N9U"
    ],
    "Link": "https://learn.newrelic.com/",
    "Package": "Online Courses",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "MasterControl ROI Calculator for Quality Management Systems",
    "Description": "Take a short survey to find out potential cost savings from fully digitizing and automating production records.",
    "Company": [
      "recn0jishIM0YfGIl"
    ],
    "Link": "https://www.mastercontrol.com/library/roi-calculator/quality/?roiUrl=++++",
    "Package": "Dynamic Resources",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Arkose Labs SMS Toll Fraud ROI Calculator",
    "Description": "SMS Toll Fraud (IRSF) is a type of cybercrime in which hackers use stolen accounts/numbers to send large volumes of SMS messages to premium rate numbers, resulting in significant charges for the account owner. The calculator estimates potential cost savings associated with stopping SMS toll fraud.",
    "Company": [
      "recXsW9aPleadHlVu"
    ],
    "Link": "https://www.arkoselabs.com/cybersecurity-roi-calculator-sms/",
    "Package": "Dynamic Resources",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Kandji MDM Solutions Buyers Guide",
    "Description": "A guide exploring what an MDM solution is and how to compare different options.",
    "Link": "https://www.kandji.io/resources/apple-mdm-solutions-comparison/",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Semrush Website Checker",
    "Description": "Free tool for looking up basic statistics about search traffic to a website.",
    "Company": [
      "recpVIIcUvzpBc9HH"
    ],
    "Link": "https://www.semrush.com/website/",
    "Package": "Dynamic Resources",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Monday.com What is Project Management? The Complete Guide [2025]",
    "Description": "A short but thorough guide to the basics of project management.",
    "Link": "https://monday.com/blog/project-management/guide-to-project-management/",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Atlassian Team Playbook",
    "Description": "A collection of \"Playbooks\" for achieving different kinds of outcomes. Each playbook includes contextual information and a series of steps. Includes resources for finding the right plays, such as a self-assessment quiz. Users can subscribe to get updated when new plays are added.",
    "Company": [
      "recRS5UKag0lGtc65"
    ],
    "Link": "https://www.atlassian.com/team-playbook",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Ahref's Free SEO Tools",
    "Description": "A suite of free tools to help with SEO. Essentially a free-tier of their paid product.",
    "Company": [
      "recDaJLjH8V6o5BXI"
    ],
    "Link": "https://ahrefs.com/free-seo-tools",
    "Package": "Dynamic Resources",
    "Publish": true
  },
  {
    "Name": "Juniper Security Intelligence",
    "Description": "Searchable database of IPS signatures and Application signatures to help protect your environment.",
    "Company": [
      "recNJ5I5lznkwgrS4"
    ],
    "Link": "https://threatlabs.juniper.net/home/search/#/list/ips",
    "Package": "Dynamic Resources",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Salesforce Trailhead",
    "Description": "Online courses presented as 1500+ modules, organized into 350+ \"trails\". Users earn points for completing courses, which show up on their online community profile.",
    "Company": [
      "recx3jhRHWikO0hiQ"
    ],
    "Link": "https://trailhead.salesforce.com/",
    "Package": "Online Courses",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Aryaka Secure Networking Academy",
    "Description": "A very short guide with \"Everything you need to know about Unified SASE as a Service in one place.\"",
    "Link": "https://www.aryaka.com/secure-networking-academy/",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Figma Resource Library",
    "Description": "A collection of articles about the basics of design, organized into top-level categories. No table of contents but there are breadcrumbs at the top that reinforce the hierarchy. Many of the articles are framed as \"What is...\" but it seems to be designed to be walked through one at a time, so it's more of a Guide than a Reference.",
    "Company": [
      "recg6tucFHhkWC0U1"
    ],
    "Link": "https://www.figma.com/resource-library/",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "iProov Biometrics Encyclopedia",
    "Description": "A collection of articles that explain key concepts related to biometrics.",
    "Company": [
      "recJC88WEr7rNbqzj"
    ],
    "Link": "https://www.iproov.com/biometric-encyclopedia",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "KnowBe4 Phishing Benchmarking Analysis Center",
    "Description": "An interactive dashboard where users can explore data about which areas of most prone to phishing attacks.",
    "Company": [
      "recCtJutQlQh3s1pl"
    ],
    "Link": "https://www.knowbe4.com/phishing-benchmarking-analysis-center",
    "Package": "Dynamic Resources",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Celonis Community",
    "Description": "An online community integrated with Celonis' online course platform, Celonis Academy. Since Celonis Academy only includes courses about using Celonis, it isn't included in the Online Courses category. But Celonis academy is open to anyone interested in process mining, so it qualifies a relational content.",
    "Company": [
      "rechgdkXBLpJ7dRf7"
    ],
    "Link": "https://community.celonis.com/",
    "Package": "Online Communities",
    "Publish": true
  },
  {
    "Name": "Okta's Identity 101",
    "Description": "A detailed guide to identity management.",
    "Link": "https://www.okta.com/identity-101/",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Medidata Clinical Trial Process Roadmap",
    "Description": "A series of pages, each with a short description and a video about a step in a typical clinical trial.",
    "Company": [
      "rec3hW1Z5Mhg6yVrO"
    ],
    "Link": "https://www.medidata.com/en/clinical-trial-process-roadmap/",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Moz Beginner's Guide to SEO",
    "Description": "Organized as a series of chapters that walk the reader through the basics of SEO in a systematic and deliberate learning lath.",
    "Company": [
      "rectExDr7MJLb7ANV"
    ],
    "Link": "https://moz.com/beginners-guide-to-seo",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Cisco Technology Learning Topics",
    "Description": "A collection of introductory articles on different networking topics.",
    "Company": [
      "recXzJY3Y3OZLGbNT"
    ],
    "Link": "https://www.cisco.com/site/us/en/learn/topics/index.html",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Slack Community",
    "Description": "An online community for Slack users. While members must have a Slack account, creating an account is free so it's effectively open to the public. Primarily for group discussions, product feedback and announcements, but includes gamification features such as badges and engagement points for participation.",
    "Company": [
      "rech7pWlPJLMtWuKd"
    ],
    "Link": "https://slack.com/community",
    "Package": "Online Communities",
    "Publish": true
  },
  {
    "Name": "DataDog Knowledge Center",
    "Description": "A collection of Overview and \"What is...\" articles about concepts related to cloud computing. It has categories rather than a table of contents, and linking between articles is minimal, but the topics are very general.",
    "Company": [
      "recOKWRKe6iZgmtkg"
    ],
    "Link": "https://www.datadoghq.com/knowledge-center/?",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Cloudflare Community",
    "Description": "An online community for Cloudflare users. While members must have a Cloudflare account, creating an account is free so it's effectively open to the public. Primarily for group discussions, product feedback and announcements.",
    "Company": [
      "recvDcullMECLJUqX"
    ],
    "Link": "https://community.cloudflare.com/",
    "Package": "Online Communities",
    "Publish": true
  },
  {
    "Name": "Sophos - Cybersecurity terms explained",
    "Description": "An alphabetical list of \"What is...\" articles covering the core terms and concepts from cybersecurity.",
    "Company": [
      "rec1OL0nhPL1pWGy3"
    ],
    "Link": "https://www.sophos.com/en-us/cybersecurity-explained",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Basecamp Getting Real",
    "Description": "Framed as an online book that describes Basecamp's unique approach to developing software so other teams can adopt their proven strategies. Some chapters are quite short, but each chapter links to the next.",
    "Company": [
      "recMnNMPViuYhtfrq"
    ],
    "Link": "https://basecamp.com/gettingreal",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Atlassian Workstream",
    "Description": "A collection of detailed guides to topics including Strategic Planning, Project Management, Collaboration and Team Management. Each of these guides consists of multiple pages, each ending with links to \"Related Content\" elsewhere in the Guide.",
    "Company": [
      "recRS5UKag0lGtc65"
    ],
    "Link": "https://www.atlassian.com/work-management",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Salesforce Trailblazer Communities",
    "Description": "An online community integrated with Salesforce's online course platform, Trailhead. Many members are not Salesforce users. The platform includes network/discussion groups, information about events, support resources, a job board and a place to display certifications/awards from Trailhead.",
    "Company": [
      "recx3jhRHWikO0hiQ"
    ],
    "Link": "https://www.salesforce.com/communities/",
    "Package": "Online Communities",
    "Publish": true
  },
  {
    "Name": "IRONSCALES Email Security Guides",
    "Description": "A collection of multi-chapter guides to Phishing Awareness, Phishing Prevention and Defending Microsoft 365.",
    "Company": [
      "recyKDRJm3kFN8E9D"
    ],
    "Link": "https://ironscales.com/guides",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Hubspot Guide to Connection in the Age of AI",
    "Description": "A guide based on \"what the top 20% of growing HubSpot customers are doing to not only create meaningful customer connections, but harness those connections to drive growth in their businesses.\" An introduction and seven chapters, arranged on a single page with links between them with very general/evergreen topics like \"How to get AI right\" and \"We need to rethink the customer journey\".",
    "Company": [
      "recxp1gQFNK7yC3Vr"
    ],
    "Link": "https://www.hubspot.com/customer-connection-guides-professional-services",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Cisco DevNet Sandbox",
    "Description": "A web-based development sandbox where users can run code to try out Cisco APIs, SDKs, etc.",
    "Company": [
      "recXzJY3Y3OZLGbNT"
    ],
    "Link": "https://developer.cisco.com/site/sandbox/",
    "Package": "Dynamic Resources",
    "Publish": true
  },
  {
    "Name": "New Relic Observability Value Calculator",
    "Description": "A self-evaluation to quantify benefits of observability to your business.",
    "Company": [
      "recU3ODSwlEqn4N9U"
    ],
    "Link": "https://newrelic.com/platform/value-calculator/",
    "Package": "Dynamic Resources",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Itential Automation Value Calculator",
    "Description": "Provides success metrics that can be achieved through automation for multiple use cases",
    "Link": "https://www.itential.com/automation-value-calculator/",
    "Package": "Dynamic Resources",
    "Publish": true,
    "Nature of Content": "Reference"
  },
  {
    "Name": "Semrush Online Classes",
    "Description": "A large collection of short online classes, mostly ranging from half and hour to an hour of content. Not organized in any particular way, so almost like a blog with courses instead of posts.",
    "Company": [
      "recpVIIcUvzpBc9HH"
    ],
    "Link": "https://www.semrush.com/academy/courses/",
    "Package": "Online Courses",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Cisco U",
    "Description": "A collection of free and paid courses targeted more at teams/companies rather than individuals.",
    "Company": [
      "recXzJY3Y3OZLGbNT"
    ],
    "Link": "https://u.cisco.com/",
    "Package": "Online Courses",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "CIsco Networking Academy",
    "Description": "Free online courses designed to help learners get jobs in networking and cybersecurity.",
    "Company": [
      "recXzJY3Y3OZLGbNT"
    ],
    "Link": "https://www.netacad.com/",
    "Package": "Online Courses",
    "Publish": true,
    "Nature of Content": "Guide"
  },
  {
    "Name": "Addgene Science Guides",
    "Description": "A relatively small number of in-depth guides to core concepts in biology. They aren't particularly interconnected but they cover basic concepts and are coordinated to be complementary. So this counts as a knowledge hub, but it's more of a Reference than a Guide.",
    "Company": [
      "rec1svLVsEWg7JsAZ"
    ],
    "Link": "https://www.addgene.org/guides/",
    "Package": "Knowledge Hubs",
    "Publish": true,
    "Nature of Content": "Reference"
  }
]