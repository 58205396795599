import React, { useState } from 'react';
import { IoClose } from "react-icons/io5";
// import Select from 'react-select';

import axios from "axios";

import './form.css';

axios.defaults.headers['Content-Type'] = 'application/json'

const origin = window.location.origin.includes('localhost') ? window.location.origin.slice(0, -5) + ':8000' : window.location.origin;

const formStates = {
    form: 'FORM',
    submitted: 'SUBMITTED',
    done: 'DONE',
    failed: 'FAILED',
}

const FormInner = (props) => {
    const [formValues, setFormValues] = useState({
        'Name': '',
        'Email': '',
        'Company': '',
        'Reason': '',
        'Comments': '',
    });

    const formField = (field) => (
        <input
            className='form-field'
            value={ formValues[field] }
            onChange={ (event) => setFormValues(prevState => ({ ...prevState, [field]: event.target.value })) }
        />
    )

    const formSelect = (field) => (
        <select className='form-field' onChange={ (event) => setFormValues(prevState => ({ ...prevState, [field]: event.target.value })) }>
            <option value=''></option>
            <option value='Question'>Ask a question</option>
            <option value='Discovery'>Schedule a free consultation</option>
            <option value='Other'>Other</option>
        </select>
    )

    const submitRequest = () => {
        axios
            .post(`${origin}/opt_in/`, formValues)
            .then(() => props.setSubmitted(formStates.done))
            .catch(() => props.setSubmitted(formStates.failed));
        props.setSubmitted(formField.submitted)
    }

    return <div className='form-outer'>
        <div className='form-row'>
            <div className='form-box'>
                <div className='form-label'>Full Name</div>
                { formField('Name') }
            </div>
            <div className='form-box'>
                <div className='form-label'>Work Email</div>
                { formField('Email') }
            </div>
        </div>
        <div className='form-row'>
            <div className='form-box'>
                <div className='form-label'>Company Name</div>
                { formField('Company') }
            </div>
            <div className='form-box'>
                <div className='form-label'>Reason for Connecting</div>
                { formSelect('Reason') }
            </div>
        </div>
        <div className='form-box'>
            <div className='form-label'>Comments</div>
            <div className='form-row'>
                <textarea
                    className='form-field text-box'
                    value={ formValues.Comments }
                    rows='6'
                    cols='50'
                    onChange={ (event) => setFormValues(prevState => ({ ...prevState, Comments: event.target.value })) }
                />
            </div>
        </div>
        <div className='submit-outer'><div className='home-button' onClick={ submitRequest }>Request</div></div>
        <div className='form-label'>Or you can reach me by email at <a href='mailto:jesse@merelogic.net'>jesse@merelogic.net</a></div>
    </div>
}

export const FormState = (props) => {
    const [submitted, setSubmitted] = useState(formStates.form);

    if (submitted === formStates.form || submitted === formStates.failed) {
        return <>
            <FormInner setSubmitted={ setSubmitted }/>
            { submitted === formStates.failed ? <div className='form-fail'>
                Something went wrong when submitting your request. Please try again or contact
                jesse@merelogic.net if the problem persists.
            </div> : null}
        </>
    } else if (submitted === formStates.done) {
        return <div className='form-outer'>
            <div className='form-final-outer'>
                <div className='form-final'>Thank you for contacting Merelogic.</div>
                <div className='form-final'>I'll reach out via the email you provided as soon as possible, usually within 24 hours.</div>
            </div>
        </div>
    } else {
        return <div className='form-outer'>
            <div className='form-final-outer'>
                <div className='form-final'>Your request is being submitted...</div>
                <div className='loader'> </div>
            </div>
        </div>
    }

}

const Form = (props) => {
    return <div className='modal-outer' onClick={ (event) => { if (event.target.className === 'modal-outer') { props.toggleFormVisible(); }}}>
        <div className='modal-inner'>
            <div className='modal-top'>
                <div className='column-title'>Contact Merelogic</div>
                <div className='form-close'  onClick={ () => { props.toggleFormVisible(); }}><IoClose size={ 32 } /></div >
            </div>
            <FormState />

        </div>
    </div>
}

export default Form;
