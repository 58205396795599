
import { ColumnLink } from "./common";

export const PostRepurpose = (props) => {
    return <></>
}

export const PostRepurposeHidden = (props) => {
    return <div className="hub-callout-box">
        <div className="hub-page-title-2">Make your existing content relational?</div>
        <div className="hub-page-text">
            I'm offering to create a free, custom project plan, including a detailed table of contents, to select companies interested in repurposing their existing content into a knowledge hub.
        </div>
        <div className="hub-page-text">
            <ColumnLink text='Learn more' url={ '/migration_plan' } />
        </div>
    </div>
}
